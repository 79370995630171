import request from './request.js'

export function localizeTopic(post_topic) {
    const user_language = localStorage.getItem('language') || navigator.language || navigator.userLanguage
    const topic_dictionary = {
        '情感': 'Emotions',
        '随写': 'Sketch',
        '学业': 'Academics',
        '求职': 'Jobs',
        '美食': 'Food',
        '跳蚤': 'Flea',
        '投票': 'Poll',
        '树洞': 'Treehole',
        '信息': 'Info',
        '交易': 'Flea',
        '分享': 'Sharing'
    }
    
    if (user_language.startsWith('zh')) {
        return post_topic
    } else {
        if (topic_dictionary.hasOwnProperty(post_topic)) {
            return topic_dictionary[post_topic]
        } else {
            return post_topic
        }
    }
}

export async function localizeString(string) {
    const user_language = localStorage.getItem('language') || navigator.language || navigator.userLanguage
    
    if (user_language.startsWith('zh')) {
        return string
    } else {
        const result = await request("/translate/string", {
            string: string,
        })
        return result.result
    }
}