<template>
  <div style="width: 100%;">
    <Navbar
      :title="post_detail.post_id ? $t('MiddlePostDetail.title') + ' #' + post_detail.post_id : $t('MiddlePostDetail.title')" />
    <Refresh :on-refresh="onRefresh">

      <div class="post-outer" v-show="post_detail.uni_post_id">
        <div class="post-outer-inner">
          <img class="post-avatar" :src="'https://i.boatonland.com/avatar/' + post_detail.user_avatar + '.svg'"
            @click="user($event, post_detail.user_is_real_name, post_detail.user_serial, post_detail.user_school_label, 'null', post_detail.user_is_org)"
            v-show="post_detail.user_avatar" />
          <div class="post-main">
            <div class="post-header">
              <div class="post-header-title">
                <div class="post-id"
                  @click="user($event, post_detail.user_is_real_name, post_detail.user_serial, post_detail.user_school_label, 'null', post_detail.user_is_org)">
                  {{ post_detail.user_is_real_name ? post_detail.user_serial : $t('MiddlePostDetail.post_owner') }}<span
                    v-if="post_detail.is_author">{{ $t('MiddlePostDetail.is_me') }}</span><span
                    v-if="post_detail.user_school_label != user_school_label">@{{ post_detail.user_school_label }}</span>
                </div>
                <div class="post-topic">{{ post_detail.post_topic }}</div>
                <div class="post-time">
                  <span v-if="post_detail.post_is_uni">UNI · </span>
                  {{ post_detail.post_create_time_display }}
                </div>
              </div>
              <div class="post-more-outer">
                <img class="comment-report-icon" src="../assets/sf-report.svg"
                  @click="report(post_detail.post_msg, -1)" />
                <img class="post-more-icon" src="../assets/sf-more.svg" @click="more" />
              </div>
            </div>
            <div>
              <div :class="post_detail.post_msg_markdown_html ? 'post-msg-markdown markdown-body' : 'post-msg'"><span
                  v-html="post_detail.post_msg_markdown_html ? post_detail.post_msg_markdown_html : post_detail.post_msg"></span>
              </div>

              <template v-if="show_translate">
                <div class="post-msg" v-if="post_detail.post_msg_en">{{ post_detail.post_msg_en }}</div>
                <div v-if="!post_detail.post_msg_en && !post_detail.is_translating" class="post-translate"
                  @click.stop="translatePost">{{ $t('MiddlePostDetail.translate') }}</div>
                <img v-if="post_detail.is_translating" class="post-translate-loading" :src="loading_src" />
              </template>

              <div class="post-image-container" v-if="post_detail.post_image">
                <img class="post-image" :src="post_detail.post_image[0]"
                  @click.stop="previewImage(post_detail.post_image[0])" />
              </div>

              <div class="function-container"
                v-if="post_detail.post_media && post_detail.post_media.media_type == 'netease'" @click="functionNetease">
                <img class="function-netease-image" :src="post_detail.post_media.netease_image" />
                <div class="function-right">
                  <div class="function-text-container">
                    <div class="function-text-title">{{ post_detail.post_media.netease_title }}</div>
                    <div class="function-text-detail">{{ post_detail.post_media.netease_artist }} -
                      {{ post_detail.post_media.netease_epname }}</div>
                  </div>
                  <img class="function-netease-icon" src="../assets/netease.svg" />
                </div>
              </div>

              <div class="function-container"
                v-if="post_detail.post_media && post_detail.post_media.media_type == 'bilibili'"
                @click="functionBilibili">
                <img class="function-bilibili-image" :src="post_detail.post_media.bilibili_image" />
                <div class="function-right">
                  <div class="function-text-container">
                    <div class="function-text-title">{{ post_detail.post_media.bilibili_title }}</div>
                    <div class="function-text-detail">{{ post_detail.post_media.bilibili_author }}</div>
                  </div>
                  <img class="function-bilibili-icon" src="../assets/bilibili.svg" />
                </div>
              </div>

              <div class="function-container"
                v-if="post_detail.post_media && post_detail.post_media.media_type == 'miniapp'" @click="functionMiniapp">
                <img class="function-miniapp-image" :src="post_detail.post_media.miniapp_image" />
                <div class="function-right">
                  <div class="function-text-container">
                    <div class="function-text-title">{{ post_detail.post_media.miniapp_msg }}</div>
                    <div class="function-text-detail">{{ post_detail.post_media.miniapp_name }}</div>
                  </div>
                  <img class="function-miniapp-icon" src="../assets/miniapp.svg" />
                </div>
              </div>

              <div class="function-container"
                v-if="post_detail.post_media && post_detail.post_media.media_type == 'quote'" @click="functionQuote">
                <img class="function-quote-image" :src="logo_src" />
                <div class="function-right">
                  <div class="function-text-container">
                    <div class="function-text-title">{{ post_detail.post_media.quote_title }}</div>
                    <div class="function-text-detail">{{ post_detail.post_media.quote_content }}</div>
                  </div>
                </div>
              </div>

              <div class="function-container" v-if="post_detail.post_media && post_detail.post_media.media_type == 'file'"
                @click="functionFile">
                <img class="function-file-image" src="../assets/file-doc.png"
                  v-if="post_detail.post_media.file_type == 'doc'" />
                <img class="function-file-image" src="../assets/file-ppt.png"
                  v-else-if="post_detail.post_media.file_type == 'ppt'" />
                <img class="function-file-image" src="../assets/file-xls.png"
                  v-else-if="post_detail.post_media.file_type == 'xls'" />
                <img class="function-file-image" src="../assets/file-pdf.png"
                  v-else-if="post_detail.post_media.file_type == 'pdf'" />
                <img class="function-file-image" src="../assets/file-unknown.png" v-else />
                <div class="function-right">
                  <div class="function-text-container">
                    <div class="function-text-title">{{ post_detail.post_media.file_name }}</div>
                    <div class="function-text-detail">{{ post_detail.post_media.file_size }}</div>
                  </div>
                </div>
              </div>

              <div class="function-container"
                v-if="post_detail.post_media && post_detail.post_media.media_type == 'hku_group'"
                @click="functionHKUGroup">
                <img class="function-miniapp-image"
                  src="https://i.boatonland.com/HKU/post/jeD8ijwBDkrKn3GsZHp2JfYhjErnTN7Y.jpeg" />
                <div class="function-right">
                  <div class="function-text-container">
                    <div class="function-text-title">{{ $t('MiddlePostDetail.hku_group_title') }}{{
                      post_detail.post_media.group_name }}</div>
                    <div class="function-text-detail">{{ $t('MiddlePostDetail.hku_group_app_name') }}</div>
                  </div>
                  <img class="function-miniapp-icon" src="../assets/miniapp.svg" />
                </div>
              </div>

              <div class="vote-container" v-if="post_detail.post_media && post_detail.post_media.media_type == 'vote'">
                <div class="vote-title">{{ post_detail.post_media.vote_title }}</div>

                <div class="vote-subtitle" v-if="post_detail.post_media.user_voted_option_title">
                  {{ $t('MiddlePostDetail.vote_have_already_vote') }}{{ post_detail.post_media.user_voted_option_title }}
                </div>
                <div class="vote-subtitle" v-else>{{ $t('MiddlePostDetail.vote_not_vote_yet') }}</div>

                <div class="vote-option-list" v-for="(option, index) in post_detail.post_media.option_list" :key="index"
                  @click="vote(option.option_id)">

                  <div class="vote-option-lower">
                    <div :class="option.is_voted ? 'vote-option-lower-text-left-voted' : 'vote-option-lower-text-left'">
                      {{ option.option_title }}</div>
                    <div :class="option.is_voted ? 'vote-option-lower-text-right-voted' : 'vote-option-lower-text-right'">
                      {{ option.option_num }}</div>
                  </div>

                  <div :class="option.is_voted ? 'vote-option-upper-voted' : 'vote-option-upper'"
                    :style="'width:' + option.option_percentage * 100 + '%'">
                    <div class="vote-option-upper-inner" :style="'width:' + 1 / option.option_percentage * 100 + '%'">
                      <div class="vote-option-upper-text-left">{{ option.option_title }}</div>
                      <div class="vote-option-upper-text-right">{{ option.option_num }}</div>
                    </div>
                  </div>

                </div>

              </div>

              <div class="article-card-container"
                v-if="post_detail.post_media && post_detail.post_media.media_type == 'article'" @click="functionArticle">
                <img :src="post_detail.post_media.article_image" class="article-image" />
                <div class="article-title">{{ post_detail.post_media.article_title }}</div>
              </div>

            </div>
          </div>

        </div>
        <div class="topic-bar">
          <div class="topic-tab">
            <button class="topic-button" @click="reply(null, null)">
              <img src="../assets/sf-comment.svg" style="height: 15px;" />
              <span class="count-text">{{ post_detail.post_comment_num }}</span>
            </button>
            <button class="topic-button" @click="follow">
              <img src="../assets/sf-star-fill.svg" style="height: 15px;" v-if="post_detail.is_following" />
              <img src="../assets/sf-star.svg" style="height: 15px;" v-else />
              <span class="count-text">{{ post_detail.post_follower_num }}</span>
            </button>
            <button class="topic-button" @click="share">
              <img src="../assets/sf-share.svg" style="height: 15px;" />
              <span class="count-text"></span>
            </button>
          </div>
        </div>
      </div>

      <div class="sharing-ad-outer" v-if="post_detail.post_media && post_detail.post_media.media_type == 'sharing'">
        <div style="font-size: 20px; font-weight: bold;">这是一篇来自于 Triple Uni「分享」区的精品文章。</div>
        <div style="font-size: 16px; margin-top: 10px;">这篇文章使用 Markdown 编写，经 Triple Uni 筛选后发布。</div>
        <div style="font-size: 16px; margin-top: 3px;">如果你觉得这篇文章对你有帮助，可以点击收藏支持作者。</div>
        <div style="font-size: 16px; margin-top: 3px;">如果你也想分享你的知识和经验，请阅读<a
            href="https://tripleuni.com/post/655080">此树洞</a>。</div>
      </div>

      <div class="ad-outer" v-if="ad_info.ad_image">
        <img class="ad-img" :src="ad_info.ad_image" @click="nav2Ad" />
      </div>

      <div class="comment-outer" v-for="index in post_detail.post_comment_num" :key="'skeleton' + index" v-show="is_skeleton">
        <div class="comment-avatar skeleton skeleton-comment-avatar" />
        <div class="comment-main">
          <div class="comment-header">
            <div class="comment-header-title">
              <div class="comment-id skeleton skeleton-comment-id"></div>
              <div class="comment-order-and-time skeleton skeleton-order-and-time">
              </div>
            </div>
            <div class="comment-reply-and-delete-outer skeleton skeleton-comment-reply-and-delete-outer">
            </div>
          </div>
          <div class="comment-msg-holder">
            <div class="comment-father-msg"></div>
            <div class="comment-msg">
              <div class="skeleton skeleton-comment-msg-line-1"></div>
              <div class="skeleton skeleton-comment-msg-line-2"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="comment-outer" v-for="(comment, index) in comment_list" :key="index">
        <img class="comment-avatar" :src="'https://i.boatonland.com/avatar/' + comment.user_avatar + '.svg'"
          @click="user($event, comment.user_is_real_name, comment.user_serial, comment.user_school_label, comment.comment_order, comment.user_is_org)" />
        <div class="comment-main">
          <div class="comment-header">
            <div class="comment-header-title">
              <div class="comment-id"
                @click="user($event, comment.user_is_real_name, comment.user_serial, comment.user_school_label, comment.comment_order)">
                {{ comment.user_is_real_name ? comment.user_serial : (comment.user_is_post_owner ?
                  $t('MiddlePostDetail.post_owner') : comment.user_simple_alias) }}<span v-if="comment.is_author">{{
    $t('MiddlePostDetail.is_me') }}</span><span v-if="comment.user_school_label != user_school_label">@{{
    comment.user_school_label }}</span>
              </div>
              <div class="comment-order-and-time">
                <span v-if="comment.comment_order == 0">G ·</span>
                <span v-else>LG{{ comment.comment_order }} · </span>
                {{ comment.comment_create_time_display }}
              </div>
            </div>
            <div class="comment-reply-and-delete-outer">
              <img class="comment-report-icon" src="../assets/sf-report.svg"
                @click="report(comment.comment_msg, comment.comment_order)" />
              <img class="comment-delete-icon" src="../assets/sf-delete.svg" v-if="comment.is_author"
                @click="deleteComment(comment.comment_id)" />
              <img class="comment-reply-icon" src="../assets/sf-reply.svg" v-else
                @click="reply(comment.comment_id, comment.comment_order, comment.user_is_org)" />
            </div>
          </div>
          <div class="comment-msg-holder">
            <div class="comment-father-msg">{{ comment.comment_father_msg }}<span
                v-if="comment.comment_father_msg_en"><br>{{ comment.comment_father_msg_en }}</span>
            </div>
            <div class="comment-msg"><span v-html="comment.comment_msg_display"></span></div>
            <template v-if="show_translate">
              <div class="comment-msg" v-if="comment.comment_msg_display_en">{{ comment.comment_msg_display_en }}</div>
              <div v-if="!comment.comment_msg_display_en && !comment.is_translating" class="post-translate"
                @click.stop="translateComment(index)">{{ $t('MiddlePostDetail.translate') }}</div>
              <img v-if="comment.is_translating" class="post-translate-loading" :src="loading_src" />
            </template>
            <div class="comment-image-container" v-if="comment.comment_image">
              <img class="comment-image" :src="comment.comment_image[0]"
                @click.stop="previewImage(comment.comment_image[0])" />
            </div>
          </div>
        </div>
      </div>

      <LoadMore :is_last="true" v-show="post_detail.uni_post_id" />

    </Refresh>
  </div>
</template>
    
<script>
import One from './One.vue'
import Navbar from './Navbar.vue'
import LoadMore from './LoadMore.vue'

import request from '../utils/request.js'
import notice from '../utils/notice.js'
import formatTime from '../utils/formatTime.js'
import { subscribeWebpush } from '../utils/webpush.js'
import { localizeTopic, localizeString } from '../utils/localize.js'
import { getLinks } from '../utils/getlinks.js'
import marked from '../utils/marked.js'

import '../assets/markdown.css'

export default {
  name: 'UniMiddlePostDetail',
  components: { Navbar, LoadMore, One },
  created() {
    window.scrollTo({
      top: 0,
    })
    this.uni_post_id = this.$route.params.uni_post_id
    if (this.$route.params.post_detail) {
      this.post_detail = this.$route.params.post_detail
      this.is_skeleton = true
    } else {
      this.$loading.show()
    }
    this.getPostDetail()
    this.logo_src = require('../assets/logo-' + localStorage.getItem('user_school_label') + '.png')
    this.user_school_label = localStorage.getItem('user_school_label')
    const user_language = localStorage.getItem('language') || navigator.language || navigator.userLanguage
    if (!user_language.startsWith('zh')) {
      this.show_translate = true
    }
    window.nav2Post = this.nav2Post
  },
  beforeRouteUpdate(to, from, next) {
    if (from.path.startsWith('/post/') && to.path.startsWith('/post/')) {
      next()
      this.$loading.show()
      this.uni_post_id = this.$route.params.uni_post_id
      this.getPostDetail()
    } else {
      next()
    }
  },
  data() {
    return {
      user_school_label: '',
      uni_post_id: null,
      post_detail: {},
      comment_list: [],
      ad_info: {},
      finishRefresh: null,
      logo_src: null,
      title: '',
      post_msg_en: '',
      loading_src: null,
      show_translate: false,
      is_skeleton: false
    }
  },
  methods: {
    getPostDetail() {
      this.getAd()
      request("/post/single/get", {
        uni_post_id: this.uni_post_id
      }, true, [400])
        .then((res) => {
          this.$loading.hide()
          this.is_skeleton = false
          if (this.finishRefresh) this.finishRefresh()
          if (!res) return
          if (res.code == 400) {
            this.$modal.show({
              content: this.$t('/post/single/get.400'),
              success: () => {
                if (window.history.length <= 1) {
                  this.$router.push('/home')
                } else {
                  this.$router.back()
                }
              }
            })
            return
          }
          res.post_detail.post_create_time_display = formatTime(res.post_detail.post_create_time)
          if (res.post_detail.user_serial) res.post_detail.user_serial = this.capitalizeFirstLetter(res.post_detail.user_serial)
          res.post_detail.post_topic = localizeTopic(res.post_detail.post_topic)
          res.post_detail.is_translating = false
          res.post_detail.post_msg = getLinks(res.post_detail.post_msg, res.post_detail.user_school_label)
          if (res.post_detail.post_msg_markdown) {
            res.post_detail.post_msg_markdown_html = marked.parse(res.post_detail.post_msg_markdown)
          } else {
            res.post_detail.post_msg_markdown_html = null
          }
          if (!res.post_detail.post_msg_en) res.post_detail.post_msg_en = ''
          res.comment_list.forEach((comment) => {
            comment.comment_create_time_display = formatTime(comment.comment_create_time)
            if (comment.user_serial) comment.user_serial = this.capitalizeFirstLetter(comment.user_serial)
            if (comment.user_simple_alias) comment.user_simple_alias = this.capitalizeFirstLetter(comment.user_simple_alias)
            comment.is_translating = false
            if (!comment.comment_msg_display_en) comment.comment_msg_display_en = ''
            if (!comment.comment_father_msg_en) comment.comment_father_msg_en = ''
            comment.comment_msg_display = getLinks(comment.comment_msg_display, comment.user_school_label)
          })
          this.post_detail = res.post_detail
          this.comment_list = res.comment_list
          const user_school_label = localStorage.getItem('user_school_label')
          if (user_school_label == "HKU") {
            document.title = this.$t('MiddlePostDetail.title') + "#" + res.post_detail.post_id + " / " + this.$t('AppNameTitle.HKU')
            this.title = this.$t('MiddlePostDetail.title') + "#" + res.post_detail.post_id + " / " + this.$t('AppNameTitle.HKU')
          }
          if (user_school_label == "CUHK") {
            document.title = this.$t('MiddlePostDetail.title') + "#" + res.post_detail.post_id + " / " + this.$t('AppNameTitle.CUHK')
            this.title = this.$t('MiddlePostDetail.title') + "#" + res.post_detail.post_id + " / " + this.$t('AppNameTitle.CUHK')
          }
          if (user_school_label == "UST") {
            document.title = this.$t('MiddlePostDetail.title') + "#" + res.post_detail.post_id + " / " + this.$t('AppNameTitle.UST')
            this.title = this.$t('MiddlePostDetail.title') + "#" + res.post_detail.post_id + " / " + this.$t('AppNameTitle.UST')
          }
        })
    },
    getAd() {
      request("/info/detailad")
        .then((res) => {
          if (!res) return
          if (res.ad_info) {
            this.ad_info = res.ad_info
          }
        })
    },
    delete() {
      this.$modal.show({
        title: this.$t('MiddlePostDetail.delete_title'),
        content: this.$t('MiddlePostDetail.delete_content'),
        show_cancel: true,
        success: (res2) => {
          if (!res2) return
          this.$loading.show()
          request("/post/single/delete", {
            uni_post_id: this.uni_post_id
          })
            .then((res) => {
              this.$loading.hide()
              if (!res) return
              notice({
                content: this.$t('/post/single/delete.' + res.code),
              })
              if (window.history.length <= 1) {
                this.$router.push('/home')
              } else {
                this.$router.back()
              }
            })
        }
      })
    },
    private() {
      this.$loading.show()
      request("/post/single/private", {
        uni_post_id: this.uni_post_id
      })
        .then((res) => {
          this.$loading.hide()
          if (!res) return
          notice({
            content: this.$t('/post/single/private.' + res.code),
          })
          this.getPostDetail()
        })
    },
    public() {
      this.$loading.show()
      request("/post/single/public", {
        uni_post_id: this.uni_post_id
      })
        .then((res) => {
          this.$loading.hide()
          if (!res) return
          notice({
            content: this.$t('/post/single/public.' + res.code),
          })
          this.getPostDetail()
        })
    },
    deleteComment(comment_id) {
      this.$modal.show({
        title: this.$t('MiddlePostDetail.delete_comment_title'),
        content: this.$t('MiddlePostDetail.delete_comment_content'),
        show_cancel: true,
        success: (res2) => {
          if (!res2) return
          this.$loading.show()
          request("/comment/delete", {
            comment_id: comment_id
          })
            .then((res) => {
              this.$loading.hide()
              if (!res) return
              notice({
                content: this.$t('/comment/delete.' + res.code),
              })
              this.getPostDetail()
            })
        }
      })
    },
    follow() {
      this.$loading.show()
      request("/post/single/follow", {
        uni_post_id: this.uni_post_id
      })
        .then((res) => {
          this.$loading.hide()
          if (!res) return
          notice({
            content: this.$t('/post/single/follow.' + res.code),
          })
          if (res.code == 200) this.checkWebpush()
          this.getPostDetail()
        })
    },
    vote(option_id) {
      this.$loading.show()
      request("/post/media/vote/vote", {
        option_id: option_id
      })
        .then((res2) => {
          if (!res2) return
          request("/post/single/get", {
            uni_post_id: this.uni_post_id
          })
            .then((res) => {
              this.$loading.hide()
              if (this.finishRefresh) this.finishRefresh()
              if (!res) return
              res.post_detail.post_create_time_display = formatTime(res.post_detail.post_create_time)
              if (res.post_detail.user_serial) res.post_detail.user_serial = this.capitalizeFirstLetter(res.post_detail.user_serial)
              res.post_detail.post_topic = localizeTopic(res.post_detail.post_topic)
              res.post_detail.is_translating = false
              res.post_detail.post_msg = getLinks(res.post_detail.post_msg, res.post_detail.user_school_label)
              if (res.post_detail.post_msg_markdown) {
                res.post_detail.post_msg_markdown_html = marked.parse(res.post_detail.post_msg_markdown)
              } else {
                res.post_detail.post_msg_markdown_html = null
              }
              if (!res.post_detail.post_msg_en) res.post_detail.post_msg_en = ''
              res.comment_list.forEach((comment) => {
                comment.comment_create_time_display = formatTime(comment.comment_create_time)
                if (comment.user_serial) comment.user_serial = this.capitalizeFirstLetter(comment.user_serial)
                if (comment.user_simple_alias) comment.user_simple_alias = this.capitalizeFirstLetter(comment.user_simple_alias)
                comment.is_translating = false
                if (!comment.comment_msg_display_en) comment.comment_msg_display_en = ''
                if (!comment.comment_father_msg_en) comment.comment_father_msg_en = ''
              })
              this.post_detail = res.post_detail
              this.comment_list = res.comment_list
              notice({
                content: this.$t('/post/media/vote/vote.' + res2.code),
              })
            })
        })
    },
    share() {
      const link = 'https://tripleuni.com/post/' + this.post_detail.uni_post_id
      try {
        navigator.clipboard.writeText(link)
        notice({
          content: this.$t('MiddlePostDetail.copy_link_success'),
        })
      } catch (e) {
        console.log(e)
        notice({
          content: this.$t('MiddlePostDetail.copy_link_fail'),
        })
      }
    },
    reply(comment_id, comment_order) {
      this.$reply.show({
        uni_post_id: this.uni_post_id,
        comment_father_id: comment_id,
        comment_order: comment_order,
        success: () => {
          this.getPostDetail()
        }
      })
    },
    report(comment_msg, comment_order) {
      this.$report.show({
        uni_post_id: this.uni_post_id,
        comment_msg: comment_msg,
        comment_order: comment_order,
        success: () => {
        }
      })
    },
    user(event, user_is_real_name, user_serial, user_school_label, comment_order, user_is_org) {
      if (user_is_real_name) {
        if (user_is_org) {
          this.$router.push('/org/' + user_serial)
        } else {
          this.$router.push('/user/' + user_school_label + '/' + user_serial)
        }
      } else {
        this.$contextmenu({
          items: [
            {
              label: this.$t('MiddlePostDetail.menu_realname_pm'),
              onClick: () => { this.createChat(true, comment_order) }
            },
            {
              label: this.$t('MiddlePostDetail.menu_anonymous_pm'),
              onClick: () => { this.createChat(false, comment_order) }
            },
          ],
          event,
          customClass: "menu-theme",
          zIndex: 99,
          minWidth: 150
        });
      }
    },
    createChat(is_realname, comment_order) {
      this.$loading.show()
      request("/pm/chat/create", {
        sender_is_real_name: is_realname,
        to_type: 'post',
        uni_post_id: this.uni_post_id,
        comment_order: comment_order
      })
        .then((res) => {
          this.$loading.hide()
          if (!res) return
          this.$router.push('/chat/' + res.chat_id)
          this.checkWebpushPm()
        })
    },
    deleteComment(comment_id) {
      this.$modal.show({
        title: this.$t('MiddlePostDetail.delete_comment_title'),
        content: this.$t('MiddlePostDetail.delete_comment_content'),
        show_cancel: true,
        success: (res2) => {
          if (!res2) return
          this.$loading.show()
          request("/comment/delete", {
            comment_id: comment_id
          })
            .then((res) => {
              this.$loading.hide()
              if (!res) return
              notice({
                content: this.$t('/comment/delete.' + res.code),
              })
              this.getPostDetail()
            })
        }
      })
    },
    more(event) {
      if (this.post_detail.is_author) {
        if (this.post_detail.post_public == 1) {
          this.$contextmenu({
            items: [
              {
                label: this.$t('MiddlePostDetail.menu_share'),
                onClick: () => { this.share() }
              },
              {
                label: this.$t('MiddlePostDetail.menu_private'),
                onClick: () => { this.private() }
              },
              {
                label: this.$t('MiddlePostDetail.menu_delete'),
                onClick: () => { this.delete() }
              },
            ],
            event,
            customClass: "menu-theme",
            zIndex: 99,
            minWidth: 150
          });
        } else if (this.post_detail.post_public == 2) {
          this.$contextmenu({
            items: [
              {
                label: this.$t('MiddlePostDetail.menu_share'),
                onClick: () => { this.share() }
              },
              {
                label: this.$t('MiddlePostDetail.menu_public'),
                onClick: () => { this.public() }
              },
              {
                label: this.$t('MiddlePostDetail.menu_delete'),
                onClick: () => { this.delete() }
              },
            ],
            event,
            customClass: "menu-theme",
            zIndex: 99,
            minWidth: 150
          });
        } else {
          this.$contextmenu({
            items: [
              {
                label: this.$t('MiddlePostDetail.menu_delete'),
                onClick: () => { this.delete() }
              },
            ],
            event,
            customClass: "menu-theme",
            zIndex: 99,
            minWidth: 150
          });
        }
      } else {
        if (this.post_detail.is_following) {
          this.$contextmenu({
            items: [
              {
                label: this.$t('MiddlePostDetail.menu_share'),
                onClick: () => { this.share() }
              },
              {
                label: this.$t('MiddlePostDetail.menu_unfollow'),
                onClick: () => { this.follow() }
              },
            ],
            event,
            customClass: "menu-theme",
            zIndex: 99,
            minWidth: 150
          });
        } else {
          this.$contextmenu({
            items: [
              {
                label: this.$t('MiddlePostDetail.menu_share'),
                onClick: () => { this.share() }
              },
              {
                label: this.$t('MiddlePostDetail.menu_follow'),
                onClick: () => { this.follow() }
              },
            ],
            event,
            customClass: "menu-theme",
            zIndex: 99,
            minWidth: 150
          });
        }
      }
    },
    functionNetease() {
      if (this.post_detail.post_media.netease_id) {
        window.open('https://music.163.com/#/song?id=' + this.post_detail.post_media.netease_id)
      }
    },
    functionBilibili() {
      if (this.post_detail.post_media.bilibili_bv) {
        window.open('https://www.bilibili.com/video/' + this.post_detail.post_media.bilibili_bv)
      }
    },
    functionMiniapp() {
      this.$modal.show({
        content: this.$t('MiddlePostDetail.function_miniapp_not_support')
      })
    },
    functionQuote() {
      if (this.post_detail.post_media.uni_post_id) {
        this.$router.push('/post/' + this.post_detail.post_media.uni_post_id)
      }
    },
    functionArticle() {
      if (this.post_detail.post_media.article_link) {
        window.open(this.post_detail.post_media.article_link)
      }
    },
    functionFile() {
      if (this.post_detail.post_media.file_link) {
        window.open(this.post_detail.post_media.file_link)
      }
    },
    functionHKUGroup() {
      if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
        this.$loading.show()
        request("/hku/group/group/link", {
          group_id: this.post_detail.post_media.group_id
        })
          .then((res) => {
            this.$loading.hide()
            if (!res) return
            setTimeout(() => {
              window.open(res.link)
            }, 10);
          })
      } else {
        notice({
          content: this.$t('MiddlePostDetail.hku_group_scan'),
        })
        this.$hevueImgPreview({
          url: 'https://i.boatonland.com/HKU/group/miniapp/' + this.post_detail.post_media.group_id + '.png',
          clickMaskCLose: true,
          controlBar: false
        })
      }
    },
    onRefresh(finishRefresh) {
      this.finishRefresh = finishRefresh
      this.getPostDetail()
    },
    previewImage(url) {
      this.$hevueImgPreview({
        url: url,
        clickMaskCLose: true,
        controlBar: false
      })
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    checkWebpush() {
      if (!("Notification" in window)) {
        return
      } else if (Notification.permission === "granted") {
        return
      } else if (Notification.permission == "denied") {
        return
      } else {
        this.$modal.show({
          title: this.$t('Webpush.follow_title'),
          content: this.$t('Webpush.follow_content'),
          show_cancel: true,
          success: (res) => {
            if (!res) return
            Notification.requestPermission().then((permission) => {
              if (permission === "granted") {
                subscribeWebpush(true)
              } else {
                this.$modal.show({
                  title: this.$t('Webpush.deny_title'),
                  content: this.$t('Webpush.deny_content'),
                  show_cancel: false,
                })
              }
            })
          }
        })
      }
    },
    checkWebpushPm() {
      if (!("Notification" in window)) {
        return
      } else if (Notification.permission === "granted") {
        return
      } else if (Notification.permission == "denied") {
        return
      } else {
        this.$modal.show({
          title: this.$t('Webpush.pm_title'),
          content: this.$t('Webpush.pm_content'),
          show_cancel: true,
          success: (res) => {
            if (!res) return
            Notification.requestPermission().then((permission) => {
              if (permission === "granted") {
                subscribeWebpush(true)
              } else {
                this.$modal.show({
                  title: this.$t('Webpush.deny_title'),
                  content: this.$t('Webpush.deny_content'),
                  show_cancel: false,
                })
              }
            })
          }
        })
      }
    },
    translatePost() {
      const user_school_label = localStorage.getItem('user_school_label')
      if (user_school_label) {
        this.loading_src = require('../assets/loading-' + localStorage.getItem('user_school_label') + '.svg');
      } else {
        this.loading_src = require('../assets/loading.svg');
      }
      this.post_detail.is_translating = true
      localizeString(this.post_detail.post_msg).then((res) => {
        this.post_detail.is_translating = false
        this.post_detail.post_msg_en = res
      })
    },
    translateComment(index) {
      const user_school_label = localStorage.getItem('user_school_label')
      if (user_school_label) {
        this.loading_src = require('../assets/loading-' + localStorage.getItem('user_school_label') + '.svg');
      } else {
        this.loading_src = require('../assets/loading.svg');
      }
      this.comment_list[index].is_translating = true
      localizeString(this.comment_list[index].comment_msg_display).then((res) => {
        this.comment_list[index].is_translating = false
        this.comment_list[index].comment_msg_display_en = res
      })
      localizeString(this.comment_list[index].comment_father_msg).then((res) => {
        this.comment_list[index].comment_father_msg_en = res
      })
    },
    nav2Post(post_id, post_school_label) {
      request("/post/single/id", {
        post_id: Number(post_id),
        post_school_label: post_school_label
      })
        .then((res) => {
          if (!res) return
          this.$router.push('/post/' + res.uni_post_id)
        })
    },
    nav2Ad() {
      switch (this.ad_info.ad_type) {
        case 'article':
          window.open(this.ad_info.article_link)
          break;
        case 'post':
          this.$router.push("/post/" + this.ad_info.uni_post_id)
          break;
        case 'inner':
          notice({
            content: this.$t('Banner.inner')
          })
          break;
        case 'miniapp':
          notice({
            content: this.$t('Banner.miniapp')
          })
          break;
        case 'none':
        default:
          break;
      }
    }
  }
}
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.425);
  z-index: 100;
}

.count-text {
  font-size: 12px;
  margin-left: 6px;
  color: var(--text-grey);
}

.topic-bar {
  margin: 0 30px;
  background-color: var(--bg);
  border-top: 0.1rem solid var(--border-grey);
}

.topic-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;
  background-color: var(--bg);
  margin-bottom: 0.1rem;
}

.topic-tab::-webkit-scrollbar {
  display: none;
}

.topic-button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 12px 0;
  transition: 0.3s;
  font-size: 1rem;
  color: var(--light-grey);
  border-radius: 10px;
  flex: 1;
  position: relative;
  min-width: 45px;
}

.topic-button:hover {
  background-color: var(--hover-grey);
  position: relative;
}

.post-outer {
  border-bottom: 0.1rem solid var(--border-grey);
}

.post-outer-inner {
  display: flex;
  padding: 15px;
}

.post-main {
  width: calc(100% - 46px);
}

.post-avatar {
  width: 36px;
  height: 36px;
  margin-right: 10px;
  cursor: pointer;
}

.post-msg {
  margin: 10px 0 8px 0;
  font-size: 15px;
  white-space: pre-wrap;
  color: var(--text);
}

.post-msg-markdown {
  margin: 10px 0 8px -46px;
}

.post-header {
  display: flex;
  padding: 20rpx 40rpx;
  justify-content: space-between;
}

.post-header-title {
  display: flex;
  align-self: center;
  max-width: 90%;
}

.post-id {
  color: var(--text);
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.post-id:hover {
  text-decoration: underline;
}

.post-topic {
  color: var(--text-grey);
  align-self: center;
  padding: 2px 8px;
  border-radius: 10px;
  background-color: var(--bg-grey);
  margin-left: 20px;
  font-size: 13px;
  white-space: nowrap;
}

.post-time {
  color: var(--text-grey);
  font-size: 16px;
  line-height: 19px;
  align-self: center;
  margin-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post-more-outer {
  display: flex;
  position: relative;
}

.post-more-icon {
  width: 18px;
  height: 18px;
  padding: 7px;
  border-radius: 50%;
}

.post-more-icon:hover {
  background-color: var(--hover-grey);
  cursor: pointer;
}

.post-image-container {
  margin-top: 10px;
  display: block;
  justify-content: center;
  align-items: center;
}

.post-image {
  max-width: min(350px, 100%);
  max-height: 400px;
  height: auto;
  border-radius: 16px;
  object-fit: contain;
  cursor: pointer;
}

.post-translate {
  font-size: 11px;
  float: right;
  color: var(--link);
  cursor: pointer;
}

.post-translate:hover {
  text-decoration: underline;
}

.post-translate-loading {
  width: 13px;
  height: 13px;
  float: right;
}

.function-container {
  margin-top: 10px;
  text-align: center;
  background-color: var(--bg);
  border-radius: 16px;
  display: flex;
  height: 75px;
  width: calc(100% - 0.2rem);
  max-width: 350px;
  overflow: hidden;
  border: 0.1rem solid var(--border-grey);
  cursor: pointer;
}

.function-text-container {
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}

.function-right {
  flex: 1;
  height: 75px;
  position: relative;
}

.function-text-title {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 230px;
  color: var(--text);
}

.function-text-detail {
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;
  color: var(--text);
}

.function-netease-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.function-miniapp-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.function-bilibili-icon {
  width: 40px;
  height: 20px;
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.function-netease-image {
  height: 75px;
  width: 75px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.function-bilibili-image {
  height: 75px;
  width: 115px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.function-miniapp-image {
  height: 75px;
  width: 75px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.function-quote-image {
  height: 75px;
  width: 75px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.function-file-image {
  height: 75px;
  width: 75px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.article-card-container {
  margin-top: 10px;
  width: calc(100% - 0.2rem);
  max-width: 350px;
  border-radius: 16px;
  border: 0.1rem solid var(--border-grey);
  overflow: hidden;
  background-color: var(--bg);
  cursor: pointer;
}

.article-image {
  width: 100%;
  border-bottom: 0.1rem solid var(--border-grey);
}

.article-title {
  color: var(--text);
  font-size: 16px;
  padding: 5px 15px 10px 15px;
}

.vote-container {
  margin-top: 10px;
  text-align: center;
  background-color: var(--bg);
  border-radius: 16px;
  width: calc(100% - 0.2rem);
  max-width: 350px;
  overflow: hidden;
  border: 0.1rem solid var(--border-grey);
}

.vote-title {
  margin: 10px 15px 0 15px;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: var(--text);
}

.vote-subtitle {
  margin: 10px 15px 0 15px;
  text-align: left;
  font-size: 13px;
  color: var(--text);
}

.vote-option-list {
  background-color: var(--bg);
  height: 35px;
  margin: 10px 10px 5px 10px;
  border-radius: 16px;
  position: relative;
  border: 0.1rem solid var(--border-grey);
  cursor: pointer;
}

.vote-option-lower {
  height: 100%;
  width: 100%;
  background-color: var(--bg);
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
}

.vote-option-upper {
  height: 100%;
  width: 0;
  background-color: var(--vote-grey);
  border-radius: 16px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.vote-option-upper-voted {
  height: 100%;
  width: 0;
  background-color: var(--main);
  border-radius: 16px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.vote-option-upper-inner {
  width: 100%;
  height: 100%;
  position: relative;
}

.vote-option-lower-text-left {
  color: var(--vote-grey);
  font-size: 15px;
  position: absolute;
  text-align: left;
  left: 15px;
  right: 50px;
  top: 7.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vote-option-lower-text-right {
  color: var(--vote-grey);
  font-size: 15px;
  position: absolute;
  right: 15px;
  top: 7.5px;
  white-space: nowrap;
}

.vote-option-lower-text-left-voted {
  color: var(--main);
  font-size: 15px;
  position: absolute;
  text-align: left;
  left: 15px;
  right: 50px;
  top: 7.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vote-option-lower-text-right-voted {
  color: var(--main);
  font-size: 15px;
  position: absolute;
  right: 15px;
  top: 7.5px;
  white-space: nowrap;
}

.vote-option-upper-text-left {
  color: var(--bg);
  font-size: 15px;
  position: absolute;
  left: 15px;
  top: 7.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vote-option-upper-text-right {
  color: var(--bg);
  font-size: 15px;
  position: absolute;
  right: 15px;
  top: 7.5px;
  white-space: nowrap;
}

.comment-outer {
  display: flex;
  border-bottom: 0.1rem solid var(--border-grey);
  padding: 15px;
}

.comment-main {
  overflow: hidden;
  width: calc(100% - 46px);
}

.comment-avatar {
  width: 36px;
  height: 36px;
  margin-right: 10px;
  cursor: pointer;
}

.comment-father-msg {
  margin-top: 10px;
  border-left: 0.1rem solid var(--hover-grey);
  padding-left: 10px;
  font-size: 15px;
  white-space: pre-wrap;
  color: var(--text-grey);
}

.comment-msg {
  margin: 10px 0 8px 0;
  font-size: 15px;
  white-space: pre-wrap;
  color: var(--text);
}

.comment-header {
  display: flex;
  padding: 20rpx 40rpx;
  justify-content: space-between;
}

.comment-header-title {
  display: flex;
  align-self: center;
}

.comment-id {
  color: var(--text);
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  align-self: center;
  cursor: pointer;
}

.comment-id:hover {
  text-decoration: underline;
}

.comment-order-and-time {
  color: var(--text-grey);
  font-size: 16px;
  line-height: 19px;
  align-self: center;
  margin-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.comment-order-and-time span {
  font-weight: 600;
  color: var(--text);
}

.comment-reply-and-delete-outer {
  display: flex;
  position: relative;
}

.comment-reply-icon {
  width: 22px;
  height: 15px;
  padding: 8.5px 5px;
  border-radius: 50%;
}

.comment-delete-icon {
  width: 15px;
  height: 18px;
  padding: 7px 8.5px;
  border-radius: 50%;
}

.comment-report-icon {
  width: 15px;
  height: 18px;
  padding: 7px 8.5px;
}

.comment-reply-icon:hover {
  background-color: var(--hover-grey);
  cursor: pointer;
}

.comment-delete-icon:hover {
  background-color: var(--hover-grey);
  cursor: pointer;
}

.comment-report-icon:hover {
  background-color: var(--hover-grey);
  cursor: pointer;
  border-radius: 50%;
}

.commwnt-image-container {
  margin-top: 10px;
  display: block;
  justify-content: center;
  align-items: center;
}

.comment-image {
  max-width: min(350px, 100%);
  max-height: 400px;
  height: auto;
  border-radius: 16px;
  object-fit: contain;
  cursor: pointer;
}

.ad-outer {
  display: none;
}

.ad-img {
  width: 360px;
  max-width: calc(100% - 30px);
  margin: 15px;
  border-radius: 16px;
  box-shadow: 0px 0px 10px var(--bg-grey);
  cursor: pointer;
}

.sharing-ad-outer {
  display: block;
  border-bottom: 0.1rem solid var(--border-grey);
  padding: 20px;
}

.skeleton-comment-id {
  height: 19px;
  width: 50px;
}

.skeleton-order-and-time {
  height: 19px;
  width: 100px;
}

.skeleton-comment-reply-and-delete-outer {
  height: 24px;
  margin-top: 4px;
  margin-bottom: 4px;
  width: 60px;
}

.skeleton-comment-msg-line-1 {
  height: 19px;
  width: 100%;
}

.skeleton-comment-msg-line-2 {
  margin-top: 10px;
  height: 19px;
  width: 30%;
}

.skeleton {
  border-radius: 10px;
  background-color: var(--bg-grey);
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: var(--bg-grey);
  }

  100% {
    background-color: var(--bg);
  }
}

@media (hover: none) {
  .topic-button:hover {
    background-color: inherit;
  }
}

@media only screen and (max-width: 700px) {

  .post-outer-inner {
    padding: 12px;
  }

  /* .post-id {
    font-size: 15px;
  } */

  .post-topic {
    font-size: 12px;
    padding: 2px 6px;
    margin-left: 10px;
  }

  /* .post-msg {
    font-size: 14px;
  } */

  .post-time {
    font-size: 14px;
    margin-left: 10px;
  }

  .topic-bar {
    margin: 0 15px;
  }

  .topic-button {
    padding: 10px 0;
  }

  /* .function-text-title {
    font-size: 13px;
  }

  .function-text-detail {
    font-size: 13px;
  }

  .vote-title {
    font-size: 15px;
  }

  .vote-subtitle {
    font-size: 12px;
  }

  .vote-option-lower-text-left {
    font-size: 14px;
  }

  .vote-option-lower-text-right {
    font-size: 14px;
  }

  .vote-option-lower-text-left-voted {
    font-size: 14px;
  }

  .vote-option-lower-text-right-voted {
    font-size: 14px;
  }

  .vote-option-upper-text-left {
    font-size: 14px;
  }

  .vote-option-upper-text-right {
    font-size: 14px;
  } */

  .comment-outer {
    padding: 12px;
  }

  /* .comment-msg {
    font-size: 14px;
  } */

  /* .comment-father-msg {
    font-size: 14px;
  } */

  /* .comment-id {
    font-size: 15px;
  } */

  .comment-order-and-time {
    font-size: 14px;
    margin-left: 10px;
  }

  .ad-outer {
    display: block;
    border-bottom: 0.1rem solid var(--border-grey);
    text-align: center;
    justify-content: center;
  }
}
</style>