import Vue from 'vue'
import App from './App.vue'
import router from './router'

import axios from 'axios'
Vue.prototype.$http = axios
axios.defaults.baseURL = 'https://api.uuunnniii.com/v4'

import i18n from "./locales";

import hevueImgPreview from 'hevue-img-preview'
Vue.use(hevueImgPreview)

import Contextmenu from "./utils/contextmenu.js"
Vue.use(Contextmenu)

import Notifications from 'vue-notification'
Vue.use(Notifications)

import Refresh from './components/Refresh'
Vue.use(Refresh)

import Loading from "./components/Loading/Loading"
Vue.use(Loading)

import Modal from "./components/Modal/Modal"
Vue.use(Modal)

import Reply from "./components/Reply/Reply"
Vue.use(Reply)

import Report from "./components/Report/Report"
Vue.use(Report)

import Media from "./components/Media/Media"
Vue.use(Media)

import './utils/elementui.js'
import { checkUnreadNotice } from './utils/unread.js'

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')

window.onunhandledrejection = function (event) {
  var reason = event.reason;
  console.log(reason);
  event.preventDefault();
}

import { launchWebSocket, closeWebsocket, db } from './utils/websocket.js'
document.addEventListener("visibilitychange", () => {
  if (document.hidden) {
    closeWebsocket()
  } else {
    launchWebSocket()
    checkUnreadNotice()
  }
});

import { subscribeWebpush, unsubscribeWebpush } from './utils/webpush.js'

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker.register('/service-worker.js', { scope: './' })
      .then(function (registration) {
        window.notificationRegistration = registration
        if (localStorage.getItem('token') && ("Notification" in window) && Notification.permission === "granted") subscribeWebpush()
      })
      .catch(function (err) {
        // console.log('ServiceWorker registration failed: ', err);
      });
  });
}

let can_install = false;

window.addEventListener('beforeinstallprompt', (event) => {
  if (can_install) return
  can_install = true
  Vue.$loading.hide();
  event.preventDefault();
  if (/(Android)/i.test(navigator.userAgent)) {
    Vue.$modal.show({
      title: i18n.t('Install.android_chrome_title'),
      content: i18n.t('Install.android_chrome_content'),
      show_cancel: false,
      success: () => {
        event.prompt()
      }
    })
  }
})

const isPwa = () => {
  return ["fullscreen", "standalone", "minimal-ui"].some(
    (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
  );
}

if (!isPwa()) {
  if (/(MicroMessenger|QQ)/i.test(navigator.userAgent)) {
    Vue.$modal.show({
      title: i18n.t('Install.wechat_title'),
      content: i18n.t('Install.wechat_content'),
      show_cancel: false,
    })
  } else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    if (/(CriOS|FxiOS)/i.test(navigator.userAgent)) {
      Vue.$modal.show({
        title: i18n.t('Install.ios_nonsafari_title'),
        content: i18n.t('Install.ios_nonsafari_content'),
        show_cancel: false,
      })
    } else {
      setTimeout(() => {
        Vue.$modal.show({
          title: i18n.t('Install.ios_safari_title'),
          content: i18n.t('Install.ios_safari_content'),
          show_cancel: false,
          success: () => {
            setTimeout(() => {
              Vue.$modal.show({
                title: i18n.t('Install.ios_safari_title_again'),
                content: i18n.t('Install.ios_safari_content_again'),
                show_cancel: false,
              })
            }, 500);
          }
        })
      }, 500);
    }
  } else if (/(Android)/i.test(navigator.userAgent)) {
    if (/Chrome/i.test(navigator.userAgent)) {
      var version = navigator.userAgent.match(/Chrome\/(\d+)/)
      var chrome_version = version && parseInt(version[1])
      if (chrome_version < 100) {
        Vue.$modal.show({
          title: i18n.t('Install.android_nonchrome_title'),
          content: i18n.t('Install.android_nonchrome_content'),
          show_cancel: false,
        })
      } else {
        Vue.$loading.show()
        setTimeout(() => {
          Vue.$loading.hide()
        }, 5000);
      }
    } else {
      Vue.$modal.show({
        title: i18n.t('Install.android_nonchrome_title'),
        content: i18n.t('Install.android_nonchrome_content'),
        show_cancel: false,
      })
    }
  }
}

import request from './utils/request.js'
import customize from './utils/customize.js'

if (localStorage.getItem('token')) {
  checkUnreadNotice()
  request("/user/terms/check")
    .then((res) => {
      if (!res) return
      if (res.code == 201) {
        Vue.$modal.show({
          title: i18n.t('Modal.terms_title'),
          is_terms: true,
          show_cancel: true,
          confirm_txt: i18n.t('Modal.terms_confirm'),
          cancel_txt: i18n.t('Modal.terms_cancel'),
          success: (res) => {
            if (res) {
              request("/user/terms/agree")
            } else {
              db.delete()
              closeWebsocket()
              localStorage.clear()
              customize()
              // router.push('/login')
              window.location.href = 'https://tripleuni.com/landing/'
            }
          }
        })
      }
    })
}

console.log(`
████████╗██████╗ ██╗██████╗ ██╗     ███████╗    ██╗   ██╗███╗   ██╗██╗
╚══██╔══╝██╔══██╗██║██╔══██╗██║     ██╔════╝    ██║   ██║████╗  ██║██║
   ██║   ██████╔╝██║██████╔╝██║     █████╗      ██║   ██║██╔██╗ ██║██║
   ██║   ██╔══██╗██║██╔═══╝ ██║     ██╔══╝      ██║   ██║██║╚██╗██║██║
   ██║   ██║  ██║██║██║     ███████╗███████╗    ╚██████╔╝██║ ╚████║██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚═╝     ╚══════╝╚══════╝     ╚═════╝ ╚═╝  ╚═══╝╚═╝
                                                                      
                                                                            
Triple Uni 招募各路程序员大佬，欢迎联系 support@tripleuni.com ，期待你的加入！

Triple Uni is recruiting talented programmers. Feel free to contact us at support@tripleuni.com. We look forward to having you join us!
`)