import Vue from 'vue'
import Router from 'vue-router'

import Login from '../components/Login.vue'
import Ban from '../components/Ban.vue'
import Main from '../components/Main.vue'

import MiddleHome from '../components/MiddleHome.vue'
import MiddleSearch from '../components/MiddleSearch.vue'
import MiddleNotice from '../components/MiddleNotice.vue'
import MiddlePm from '../components/MiddlePm.vue'
import MiddleMine from '../components/MiddleMine.vue'
import MiddleWrite from '../components/MiddleWrite.vue'
import MiddlePostDetail from '../components/MiddlePostDetail.vue'
import MiddleUser from '../components/MiddleUser.vue'
import MiddleOrg from '../components/MiddleOrg.vue'
import MiddleAbout from '../components/MiddleAbout.vue'
import MiddleEdit from '../components/MiddleEdit.vue'

import RightRecommend from '../components/RightRecommend.vue'
import RightChat from '../components/RightChat.vue'
import RightChatbot from '../components/RightChatbot.vue'
import RightWrite from '../components/RightWrite.vue'

import OrgMain from '../components/OrgMain.vue'
import OrgWrite from '../components/OrgWrite.vue'
import OrgProfile from '../components/OrgProfile.vue'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    { path: '/login', component: Login },
    { path: '/ban', component: Ban },
    { path: '/', redirect: '/home' },
    {
      path: '/main',
      component: Main,
      redirect: '/home',
      children: [
        {
          path: '/home', components: {
            middle: MiddleHome,
            right: RightRecommend
          },
          meta: { keepAlive: true }
        },
        {
          path: '/search/:key_word?', components: {
            middle: MiddleSearch,
            right: RightRecommend
          },
          meta: { keepAlive: true }
        },
        {
          path: '/notice', components: {
            middle: MiddleNotice,
            right: RightRecommend
          },
          meta: { keepAlive: true }
        },
        {
          path: '/chat/chatbot', components: {
            middle: MiddlePm,
            right: RightChatbot
          }
        },
        {
          path: '/chat/:chat_id?', components: {
            middle: MiddlePm,
            right: RightChat
          }
        },
        {
          path: '/mine', components: {
            middle: MiddleMine,
            right: RightRecommend
          },
          meta: { keepAlive: true }
        },
        {
          path: '/write', components: {
            middle: MiddleWrite,
            right: RightWrite
          }
        },
        {
          path: '/edit', components: {
            middle: MiddleEdit,
            right: RightRecommend
          },
        },
        {
          path: '/about', components: {
            middle: MiddleAbout,
            right: RightRecommend
          },
        },
        {
          path: '/post/:uni_post_id?', components: {
            middle: MiddlePostDetail,
            right: RightRecommend
          },
          name: 'post'
        },
        {
          path: '/user/:user_school_label/:user_serial?', components: {
            middle: MiddleUser,
            right: RightRecommend
          },
          meta: { keepAlive: true }
        },
        {
          path: '/org/:org_name', components: {
            middle: MiddleOrg,
            right: RightRecommend
          },
          meta: { keepAlive: true }
        }
      ]
    },
    { path:'/orgadmin',
      component: OrgMain,
      redirect: '/orgadmin/write',
      children: [
        { path:'/orgadmin/write', component: OrgWrite },
        { path:'/orgadmin/profile', component: OrgProfile },
      ]
    },
    {
      path: '*',
      redirect: '/home'
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (from.path.startsWith('/post/') && to.path.startsWith('/post/') && to.path.query && !to.path.query.method) {
    next({
      path: to.path,
      query: {
        method: 'refresh'
      }
    },
    { replace: true }
    )
  } else {
    next()
  }
})

export default router