<template>
  <div style="width: 100%;">
    <Navbar :title="$t('MiddlePm.title')" :show_back="false" />
    <div class="pm-outer-notice" @click="nav2('/notice', { method: 'refresh' })">
      <img class="pm-avatar" src="https://i.boatonland.com/notification.png" />
      <div class="pm-main">
        <div class="pm-info notice">
          <div class="pm-info-alias notice" style="flex:1;max-width: none;"> {{ $t('MiddleNotice.title') }}</div>
          <div class="pm-content-tip notice" v-if="unread_notice > 0">{{ unread_notice }}</div>
        </div>
      </div>
    </div>
    <div :class="($route.path == '/chat/chatbot') ? 'pm-outer pm-outer-active' : 'pm-outer'"
      @click="nav2('/chat/chatbot')">
      <img class="pm-avatar" :src="'https://i.boatonland.com/avatar/chatbot.svg'" />
      <div class="pm-main">
        <div class="pm-info">
          <div class="pm-info-alias"> {{ $t('MiddlePm.chatbot_name') }}</div>
          <!-- <div class="pm-info-time">{{ chat.chat_update_time_display }}</div> -->
        </div>
        <div class="pm-content">
          <div class="pm-content-msg">{{ this.$t('MiddlePm.chatbot_intro') }}</div>
          <!-- <div class="pm-content-tip" v-if="chat.chat_unread_count > 0">{{ chat.chat_unread_count }}</div> -->
        </div>
      </div>
    </div>
    <div :class="($route.path == '/chat/' + chat.chat_id) ? 'pm-outer pm-outer-active' : 'pm-outer'"
      v-for="(chat, index) in chat_list" :key="index" @click="nav2('/chat/' + chat.chat_id)"
      @contextmenu.prevent="rightClick($event, chat)">
      <img class="pm-avatar" :src="'https://i.boatonland.com/avatar/' + chat.user_avatar + '.svg'" />
      <div class="pm-main">
        <div class="pm-info">
          <div class="pm-info-alias"> {{ chat.user_alias }}</div>
          <div class="pm-info-time">{{ formatTime(chat.chat_update_time) }}</div>
        </div>
        <div class="pm-content">
          <div class="pm-content-msg">{{ chat.chat_latest_msg }}</div>
          <div class="pm-content-tip" v-if="chat.chat_unread_count > 0">{{ chat.chat_unread_count }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import Navbar from './Navbar.vue'

import { useObservable } from '@vueuse/rxjs'
import { liveQuery } from 'dexie'
import { db } from '../utils/websocket.js'
import request from '../utils/request.js'
import notice from '../utils/notice.js'
import formatTime from '../utils/formatTime.js'

export default {
  name: 'UniMiddlePm',
  components: { Navbar },
  setup() {
    return {
      chat_list: useObservable(
        liveQuery(async () => {
          return await db.chat.orderBy("chat_update_time").reverse().toArray()
        })
      )
    };
  },
  mounted() {
    this.updateUnreadCount()
    window.addEventListener('updateUnreadNotice', () => {
      this.updateUnreadCount()
    })
  },

  data() {
    return {
      selected_chat_id: null,
      is_long_press: false,
      unread_notice: 0
    }
  },
  methods: {
    nav2(url, query = {}) {
      this.$router.push({ path: url, query: query })
    },
    delete() {
      const chat_id = this.selected_chat_id
      this.selected_chat_id = null
      this.$modal.show({
        title: this.$t('MiddlePm.delete_modal_title'),
        content: this.$t('MiddlePm.delete_modal_content'),
        show_cancel: true,
        success: (res) => {
          if (!res) return
          notice({
            content: this.$t('MiddlePm.delete_modal_confirm'),
          })
          db.chat.delete(chat_id)
          request("/pm/chat/ban", {
            chat_id: chat_id
          })
        }
      })
    },
    rightClick(event, chat) {
      console.log(event)
      this.selected_chat_id = chat.chat_id
      this.$contextmenu({
        items: [
          {
            label: this.$t('MiddlePm.delete'),
            onClick: () => {
              this.delete()
            }
          }
        ],
        event,
        customClass: "menu-theme",
        zIndex: 99,
        minWidth: 150
      });
      return false
    },
    touchStart(event, chat) {
      this.is_long_press = false
      this.timer = setTimeout(() => {
        this.is_long_press = true
        const pointerEvent = new PointerEvent('pointerdown', {
          pointerId: event.touches[0].identifier,
          clientX: event.touches[0].clientX,
          clientY: event.touches[0].clientY,
        });
        this.rightClick(pointerEvent, chat)
      }, 500)
    },
    touchEnd(chat) {
      clearTimeout(this.timer)
      if (!this.is_long_press) {
        this.$router.push('/chat/' + chat.chat_id)
      }
    },
    formatTime(e) {
      return formatTime(e)
    },
    updateUnreadCount() {
      this.unread_notice = localStorage.getItem('unread_notice')
    }
  }
}
</script>

<style scoped>
.pm-outer {
  border-bottom: 0.1rem solid var(--border-grey);
  padding: 15px;
  position: relative;
  display: flex;
}

.pm-outer-notice {
  display: none;
}

.pm-outer:hover {
  background-color: var(--hover-grey);
  cursor: pointer;
}

.pm-outer-active {
  background-color: var(--hover-grey);
  cursor: pointer;
}

.pm-outer-notice:hover {
  background-color: var(--hover-grey);
  cursor: pointer;
}

.pm-avatar {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}


.pm-main {
  overflow: hidden;
  width: calc(100% - 46px);
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

.pm-info {
  display: flex;
  align-items: center;
  width: 100%;
  height: 25px;
  line-height: 25px;
}

.pm-info .notice {
  margin-top: 22px;
}

.pm-info-alias {
  font-size: 15px;
  color: var(--text);
  max-width: calc(100% - 30px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pm-info-alias .notice {
  flex: 1;
  max-width: none;
}

.pm-info-time {
  flex: 1;
  color: var(--text-grey);
  font-size: 11px;
  text-align: right;
}

.pm-content {
  display: flex;
}

.pm-content-msg {
  flex: 1;
  height: 100%;
  font-size: 12px;
  color: var(--text-grey);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.pm-content-tip {
  display: block;
  height: 16px;
  line-height: 16px;
  background: var(--tip);
  border-radius: 8px;
  font-size: 10px;
  color: #FFFFFF;
  padding: 0 6px;
}

@media only screen and (max-width: 700px) {
  .pm-outer-notice {
    border-bottom: 0.1rem solid var(--border-grey);
    padding: 15px;
    position: relative;
    display: flex;
  }

}
</style>