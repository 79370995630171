import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'
import router from '../router'
import i18n from '../locales'
import { _ } from 'core-js'

let request_num = 0
let start_timestamp = Date.now()
const gap_time = 2000

const checkRequestNum = () => {
    setTimeout(() => {
        if (request_num <= 0) {
            let event = new Event("finishInitLoading")
            window.dispatchEvent(event)
        } else {
            checkRequestNum()
        }
    }, gap_time);
}

checkRequestNum()

export default async function request(url, body = {}, include_token = true, exclude_code = []){
    if (include_token){
        const token = localStorage.getItem('token');
        if(!token){
            if(!window.location.pathname.startsWith('/login')){
                // router.push('/login?callback=' + window.location.pathname)
                window.location.href = 'https://tripleuni.com/landing/?callback=' + encodeURIComponent(window.location.pathname)
            }
            return false
        }
        Object.assign(body, {token: token});
    }

    const user_language = localStorage.getItem('language') || navigator.language || navigator.userLanguage
    var language = "en"
    if (user_language.startsWith('zh')) {
        language = "zh-CN"
    }
    Object.assign(body, {language: language})

    request_num += 1

    try{
        const {data:res} = await axios.post(url + '.php', qs.stringify(body), {timeout: 15000});
        
        request_num -= 1
        if (request_num <= 0) {
            setTimeout(() => {
                if (request_num <= 0 && Date.now() - start_timestamp > gap_time) {
                    let event = new Event("finishInitLoading")
                    window.dispatchEvent(event)
                }
            }, 200)
        }

        if(!res || !res.code){
            Vue.$modal.show({
                title: i18n.t('Modal.error'),
                content: i18n.t('Request.error'),
                show_cancel: false,
            });
        }
        if(res.code == 801){
            if(!window.location.pathname.startsWith('/login')){
                localStorage.clear()
                // router.push('/login?callback=' + window.location.pathname)
                window.location.href = 'https://login.tripleuni.com/TripleUni?callback=' + encodeURIComponent(window.location.pathname)
            }
            return false
        }
        if(res.code == 803 || res.code == 804){
            if(window.location.pathname != '/ban'){
                router.push('/ban')
            }
            return false
        }
        if(res.code == 902){
            Vue.$modal.show({
                title: i18n.t('Modal.error'),
                content: res.msg,
                show_cancel: false,
            });
            return false
        }
        if(res.code >= 800){
            Vue.$modal.show({
                title: i18n.t('Modal.error'),
                content: i18n.t('Request.' + res.code),
                show_cancel: false,
            });
            return false
        }
        if(res.code >= 400 && !exclude_code.includes(res.code)){
            Vue.$modal.show({
                title: i18n.t('Modal.error'),
                content: i18n.t(url + '.' + res.code),
                show_cancel: false,
            });
            return false
        }
    
        return res
    }catch(e){
        request_num -= 1
        return false
    }

    
}