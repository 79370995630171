<template>
    <div style="width: 100%;">
        <div :class="show_border ? 'post-outer' : 'post-outer no-border'" v-if="type == 'post'" @click="nav2PostDetail">
            <img class="post-avatar" :src="'https://i.boatonland.com/avatar/' + data.user_avatar + '.svg'" />
            <div class="post-main">
                <div class="post-header">
                    <div class="post-header-title">
                        <div class="post-id">#{{ data.post_id }}</div>
                        <div class="post-topic">{{ data.post_topic }}</div>
                    </div>
                    <div class="post-follower-comment-outer">
                        <div style="padding-right: 8px;">
                            <img v-if="data.is_following" class="post-follower-icon" src="../assets/sf-star-fill.svg" />
                            <img v-else class="post-follower-icon" src="../assets/sf-star.svg" />
                        </div>
                        <div class="post-follower-num" style="padding-right: 20px;">{{ data.post_follower_num }}</div>
                        <div style="padding-right: 8px;">
                            <img class="post-follower-icon" src="../assets/sf-comment.svg" />
                        </div>
                        <div class="post-follower-num">{{ data.post_comment_num }}</div>
                    </div>
                </div>
                <div>
                    <div class="post-function-outer" v-if="data.post_media && data.post_media.media_type == 'netease'">
                        <div class="post-function-title">{{ data.post_media.netease_title }} -
                            {{ data.post_media.netease_artist }}</div>
                        <div class="post-function-icon-outer-netease">
                            <img src="../assets/netease.svg" class="post-icon-netease" />
                        </div>
                    </div>

                    <div class="post-function-outer" v-if="data.post_media && data.post_media.media_type == 'bilibili'">
                        <div class="post-function-title">{{ data.post_media.bilibili_title }}</div>
                        <div class="post-function-icon-outer-bilibili">
                            <img src="../assets/bilibili.svg" class="post-icon-bilibili" />
                        </div>
                    </div>

                    <div class="post-function-outer" v-if="data.post_media && data.post_media.media_type == 'vote'">
                        <div class="post-function-title">{{ data.post_media.vote_title }}</div>
                        <div class="post-function-icon-outer-vote">
                            <img src="../assets/vote.svg" class="post-icon-vote" />
                        </div>
                    </div>

                    <div class="post-function-outer" v-if="data.post_media && data.post_media.media_type == 'quote'">
                        <div class="post-function-title">{{ data.post_media.quote_title }}</div>
                    </div>

                    <div class="post-function-outer" v-if="data.post_media && data.post_media.media_type == 'miniapp'">
                        <div class="post-function-title">{{ data.post_media.miniapp_name }}</div>
                        <div class="post-function-icon-outer-miniapp">
                            <img src="../assets/miniapp.svg" class="post-icon-miniapp" />
                        </div>
                    </div>

                    <div class="post-function-outer" v-if="data.post_media && data.post_media.media_type == 'article'">
                        <div class="post-function-title">{{ data.post_media.article_title }}</div>
                        <div class="post-function-icon-outer-article">
                            <img src="../assets/article.svg" class="post-icon-article" />
                        </div>
                    </div>

                    <div class="post-function-outer" v-if="data.post_media && data.post_media.media_type == 'file'">
                        <div class="post-function-title">{{ data.post_media.file_name }}</div>
                        <div class="post-function-icon-outer-file">
                            <img src="../assets/file.svg" class="post-icon-file" />
                        </div>
                    </div>

                    <div class="post-function-outer" v-if="data.post_media && data.post_media.media_type == 'hku_group'">
                        <div class="post-function-title">{{ $t('One.hku_group_title') }}{{ data.post_media.group_name }}
                        </div>
                        <div class="post-function-icon-outer-miniapp">
                            <img src="../assets/miniapp.svg" class="post-icon-miniapp" />
                        </div>
                    </div>

                    <div class="post-msg">{{ data.post_msg_short }}<span v-if="!data.post_msg_short_is_complete">...</span>
                    </div>

                    <template v-if="show_translate">
                        <div class="post-msg" v-if="data.post_msg_en || post_msg_en">{{ data.post_msg_en || post_msg_en }}
                        </div>
                        <div v-if="!data.post_msg_en && !post_msg_en && !is_translating" class="post-translate"
                            @click.stop="translate">{{ $t('One.translate') }}</div>
                        <img v-if="is_translating" class="post-translate-loading" :src="loading_src" />
                    </template>

                    <div v-if="!data.post_msg_short_is_complete" class="post-loadmore">{{ $t('One.load_more') }}</div>
                    <div :class="data.post_msg_short_is_complete ? 'post-image-container' : 'post-image-container-margin'"
                        v-if="data.post_image">
                        <img class="post-image" :src="data.post_image[0]" @click.stop="previewImage(data.post_image[0])" />
                    </div>
                </div>
            </div>
        </div>

        <div :class="show_border ? 'post-outer' : 'post-outer no-border'" v-if="type == 'article'" @click="nav2Article">
            <img class="article-avatar" :src="'https://i.boatonland.com/avatar/' + data.user_avatar + '.svg'"
                @click.stop="nav2Org" />
            <div class="article-main">
                <div class="article-header">
                    <div class="article-header-title">
                        <div class="article-alias" @click.stop="nav2Org">{{ data.user_alias }}</div>
                    </div>
                </div>
                <div class="article-card-container">
                    <img :src="data.article_image" class="article-image" />
                    <div class="article-title">{{ data.article_title }}</div>
                </div>
            </div>
        </div>

        <div :class="show_border ? 'hku-group-add-outer' : 'hku-group-add-outer no-border'" v-if="type == 'hku_group_add'"
            @click="addHKUGroup">
            <div class="hku-group-add-container">
                <img class="hku-group-add-image"
                    src="https://i.boatonland.com/HKU/post/jeD8ijwBDkrKn3GsZHp2JfYhjErnTN7Y.jpeg" />
                <div class="hku-group-add-right">
                    <div class="hku-group-add-text-container">
                        <div class="hku-group-add-text-title">{{ $t('MiddlePostDetail.hku_group_title') }}{{
                            data.group_name }}</div>
                        <div class="hku-group-add-text-detail">{{ $t('MiddlePostDetail.hku_group_app_name') }}</div>
                    </div>
                    <img class="hku-group-add-icon" src="../assets/miniapp.svg" />
                </div>
            </div>
        </div>

        <div :class="show_border ? 'post-outer' : 'post-outer no-border'" v-if="type == 'sharing'" @click="nav2PostDetail">
            <img class="sharing-avatar" :src="'https://i.boatonland.com/avatar/' + data.user_avatar + '.svg'"/>
            <div class="sharing-main">
                <div class="sharing-header">
                    <div class="sharing-header-title">
                        <div class="sharing-alias">{{ data.user_alias }}</div>
                    </div>
                </div>
                <div class="sharing-card-container">
                    <img :src="data.post_media.sharing_image" class="sharing-image" />
                    <div class="sharing-title">{{ data.post_media.sharing_title }}</div>
                    <div class="sharing-abstract">{{ data.post_media.sharing_abstract }}</div>
                    <div class="sharing-info-container">
                        <div class="sharing-info-item">
                            <img class="sharing-info-item-icon" :src="sharing_author_src">
                            {{ data.post_media.sharing_author }}
                        </div>
                        <div class="sharing-info-item" v-for="(tag, index) in data.post_media.sharing_tag_list" :key="index">
                            <img class="sharing-info-item-icon" :src="sharing_tag_src">
                            {{ tag }}
                        </div>
                        <div class="sharing-info-item">
                            <img class="sharing-info-item-icon" :src="sharing_time_src">
                            {{ data.post_media.sharing_create_time_display }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
      
<script>
import { localizeString } from '../utils/localize.js'
import { formatTimeToDate } from '../utils/formatTime.js'
import request from '../utils/request.js'
import notice from '../utils/notice.js'

export default {
    name: 'UniOne',
    created() {
        const user_language = localStorage.getItem('language') || navigator.language || navigator.userLanguage
        if (!user_language.startsWith('zh')) {
            this.show_translate = true
        }
        this.sharing_author_src = require('../assets/sharing-author-' + localStorage.getItem('user_school_label') + '.svg')
        this.sharing_tag_src = require('../assets/sharing-tag-' + localStorage.getItem('user_school_label') + '.svg')
        this.sharing_time_src = require('../assets/sharing-time-' + localStorage.getItem('user_school_label') + '.svg')
    },
    props: {
        type: String,
        data: Object,
        show_border: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            post_msg_en: '',
            is_translating: false,
            loading_src: null,
            show_translate: false,
            sharing_author_src: null,
            sharing_tag_src: null,
            sharing_time_src: null
        }
    },
    methods: {
        translate() {
            const user_school_label = localStorage.getItem('user_school_label')
            if (user_school_label) {
                this.loading_src = require('../assets/loading-' + localStorage.getItem('user_school_label') + '.svg');
            } else {
                this.loading_src = require('../assets/loading.svg');
            }
            this.is_translating = true
            localizeString(this.data.post_msg).then((res) => {
                this.is_translating = false
                this.post_msg_en = res
            })
        },
        nav2PostDetail() {
            // console.log(this.data)
            this.$router.push({
                name: "post",
                params: {
                    uni_post_id: this.data.uni_post_id,
                    post_detail: this.data
                }
            })
        },
        nav2Article() {
            window.open(this.data.article_link)
        },
        nav2Org() {
            this.$router.push("/org/" + this.data.user_serial)
        },
        previewImage(url) {
            this.$hevueImgPreview({
                url: url,
                clickMaskCLose: true,
                controlBar: false
            })
        },
        addHKUGroup() {
            if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
                this.$loading.show()
                request("/hku/group/group/link", {
                    group_id: this.data.group_id
                })
                    .then((res) => {
                        this.$loading.hide()
                        if (!res) return
                        setTimeout(() => {
                            window.open(res.link)
                        }, 10);
                    })
            } else {
                notice({
                    content: this.$t('MiddlePostDetail.hku_group_scan'),
                })
                this.$hevueImgPreview({
                    url: 'https://i.boatonland.com/HKU/group/miniapp/' + this.data.group_id + '.png',
                    clickMaskCLose: true,
                    controlBar: false
                })
            }
        },
    }
}
</script>

<style scoped>
.no-border {
    border: none !important;
}

.post-outer {
    display: flex;
    border-bottom: 0.1rem solid var(--border-grey);
    padding: 15px;
    background-color: var(--bg);
    cursor: pointer;
    transition: 0.2s;
}

.post-outer:hover {
    background-color: var(--hover-grey);
}

.post-main {
    overflow: hidden;
    width: calc(100% - 46px);
}

.post-avatar {
    width: 36px;
    height: 36px;
    margin-right: 10px;
}

.post-msg {
    margin: 10px 0 8px 0;
    font-size: 15px;
    white-space: pre-wrap;
    color: var(--text);
}

.post-header {
    display: flex;
    justify-content: space-between;
}

.post-header-title {
    display: flex;
    align-self: center;
}

.post-id {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    align-self: center;
    color: var(--text);
}

.post-topic {
    color: var(--text-grey);
    align-self: center;
    padding: 2px 8px;
    border-radius: 10px;
    background-color: var(--bg-grey);
    margin-left: 20px;
    font-size: 13px;
}

.post-follower-comment-outer {
    display: flex;
    position: relative;
}

.post-follower-icon {
    width: 15px;
    height: 15px;
    margin-top: 5px
}

.post-follower-num {
    color: var(--text-grey);
    font-size: 15px;
    margin-top: 1px;
    align-self: center;
}

.post-image-container {
    margin-top: 10px;
    display: block;
    justify-content: center;
    align-items: center;
}

.post-image-container-margin {
    margin-top: 50px;
    display: block;
    justify-content: center;
    align-items: center;
}

.post-image {
    max-width: 100%;
    max-height: 400px;
    height: auto;
    border-radius: 16px;
    object-fit: contain;
}

.post-loadmore {
    font-size: 11px;
    color: var(--text-grey);
    float: right
}

.post-function-outer {
    display: flex;
    background-color: var(--border-grey);
    width: 100%;
    margin: 10px 0;
    padding: 5px 10px;
    box-sizing: border-box;
    border-radius: 10px;
    position: relative;
}

.post-function-title {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: var(--text);
}

.post-function-icon-outer-netease {
    text-align: right;
    height: 20px;
    width: 20px;
    align-self: center;
}

.post-function-icon-outer-bilibili {
    text-align: right;
    height: 20px;
    width: 40px;
    align-self: center;
}

.post-function-icon-outer-vote {
    text-align: right;
    height: 20px;
    width: 20px;
    align-self: center;
}

.post-function-icon-outer-miniapp {
    text-align: right;
    height: 20px;
    width: 20px;
    align-self: center;
}

.post-function-icon-outer-article {
    text-align: right;
    height: 20px;
    width: 20px;
    align-self: center;
}

.post-function-icon-outer-file {
    text-align: right;
    height: 20px;
    width: 20px;
    align-self: center;
}

.post-icon-netease {
    height: 20px;
    width: 20px;
}

.post-icon-bilibili {
    height: 20px;
    width: 40px;
}

.post-icon-vote {
    height: 20px;
    width: 20px;
}

.post-icon-miniapp {
    height: 20px;
    width: 20px;
}

.post-icon-article {
    height: 20px;
    width: 20px;
}

.post-icon-file {
    height: 20px;
    width: 20px;
}

.post-translate {
    font-size: 11px;
    float: right;
    color: var(--link);
    cursor: pointer;
    padding-left: 20px;
}

.post-translate:hover {
    text-decoration: underline;
}

.post-translate-loading {
    width: 13px;
    height: 13px;
    float: right;
}

.article-outer {
    display: flex;
    border-bottom: 0.1rem solid var(--border-grey);
    padding: 15px;
    background-color: var(--bg);
    cursor: pointer;
    transition: 0.2s;
}

.article-outer:hover {
    background-color: var(--hover-grey);
}

.article-main {
    overflow: hidden;
    width: calc(100% - 46px);
}

.article-avatar {
    width: 36px;
    height: 36px;
    margin-right: 10px;
    cursor: pointer;
}

.article-header {
    display: flex;
    padding: 20rpx 40rpx;
    justify-content: space-between;
}

.article-header-title {
    display: flex;
    align-self: center;
}

.article-alias {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    align-self: center;
    cursor: pointer;
    color: var(--text);
}

.article-alias:hover {
    text-decoration: underline;
}

.article-card-container {
    margin-top: 10px;
    max-width: 400px;
    border-radius: 16px;
    border: 0.1rem solid var(--border-grey);
    overflow: hidden;
    background-color: var(--bg);
}

.article-image {
    width: 100%;
    border-bottom: 0.1rem solid var(--border-grey);
}

.article-title {
    color: var(--text);
    font-size: 16px;
    padding: 5px 15px 10px 15px;
}

.sharing-main {
    overflow: hidden;
    width: calc(100% - 46px);
}

.sharing-avatar {
    width: 36px;
    height: 36px;
    margin-right: 10px;
    cursor: pointer;
}

.sharing-header {
    display: flex;
    padding: 20rpx 40rpx;
    justify-content: space-between;
}

.sharing-header-title {
    display: flex;
    align-self: center;
}

.sharing-alias {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    align-self: center;
    cursor: pointer;
    color: var(--text);
}

.sharing-card-container {
    margin-top: 10px;
    max-width: 500px;
    border-radius: 16px;
    border: 0.1rem solid var(--border-grey);
    overflow: hidden;
    background-color: var(--bg);
}

.sharing-image {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    border-bottom: 0.1rem solid var(--border-grey);
}

.sharing-title {
    color: var(--text);
    font-size: 16px;
    font-weight: bold;
    padding: 5px 15px 5px 15px;
}

.sharing-info-container {
    padding: 0 15px 10px 15px;
    display: flex;
    flex-flow: row wrap;
}

.sharing-info-item {
    font-size: 12px;
    padding: 4px 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    border-radius: 10px;
    background-color: var(--hover-grey);
    color: var(--text-grey);
    display: flex;
}

.sharing-info-item-icon {
    width: 13px;
    height: 13px;
    margin: 1px 3px 0 -3px;
    /* margin-top: 5px; */
}

.sharing-abstract {
    color: var(--text-grey);
    font-size: 14px;
    padding: 0 15px 10px 15px;
}

.hku-group-add-outer {
    display: flex;
    border-bottom: 0.1rem solid var(--border-grey);
    padding: 15px;
    background-color: var(--bg);
    cursor: pointer;
    text-align: center;
    justify-content: center;
    transition: 0.2s;
}

.hku-group-add-outer:hover {
    background-color: var(--hover-grey);
}

.hku-group-add-container {
    text-align: center;
    background-color: var(--bg);
    border-radius: 16px;
    display: flex;
    height: 75px;
    width: calc(100% - 0.2rem);
    max-width: 350px;
    overflow: hidden;
    border: 0.1rem solid var(--border-grey);
    cursor: pointer;
}

.hku-group-add-image {
    height: 75px;
    width: 75px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
}

.hku-group-add-right {
    flex: 1;
    height: 75px;
    position: relative;
}

.hku-group-add-text-container {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
}

.hku-group-add-text-title {
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 230px;
    color: var(--text);
}

.hku-group-add-text-detail {
    text-align: left;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 230px;
    color: var(--text);
}

.hku-group-add-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 8px;
    right: 8px;
}

@media (hover: none) {
    .post-outer:hover {
        background-color: inherit;
    }

    .article-outer:hover {
        background-color: inherit;
    }

    .hku-group-add-outer:hover {
        background-color: inherit;
    }
}

@media only screen and (max-width: 700px) {

    .post-outer {
        padding: 12px;
    }

    /* .post-id {
        font-size: 15px;
    } */

    .post-topic {
        font-size: 12px;
        padding: 2px 6px;
    }

    .post-follower-icon {
        width: 12px;
        height: 12px;
        margin-top: 5px
    }

    .post-follower-num {
        font-size: 14px;
    }

    /* .post-msg {
        font-size: 14px;
    } */

    .article-alias {
        font-size: 15px;
    }

    .article-title {
        font-size: 15px;
    }


    .hku-group-add-outer {
        padding: 12px;
    }

}</style>