<template>
    <div class="body">

    </div>
</template>

<script>
import request from '../utils/request.js'
import customize from '../utils/customize.js'
import { subscribeWebpush } from '../utils/webpush.js'
import { launchWebSocket } from '../utils/websocket.js'
import { checkUnreadNotice } from '../utils/unread.js'

export default {
    name: 'UniLogin',
    created() {
        this.ssoLogin()
    },
    methods: {
        ssoLogin() {
            if (!this.$route.query.code) {
                this.$modal.show({
                    title: this.$t('Modal.error'),
                    content: this.$t('/user/register/web/sso.400'),
                    show_cancel: false,
                    success: () => {
                        window.location.href = 'https://tripleuni.com/landing/'
                    }
                })
                return
            }
            request("/user/register/web/sso", {
                code: this.$route.query.code,
            }, false, [400, 401, 500])
                .then((res) => {
                    if (!res) return
                    if (res.code == 400) {
                        this.$modal.show({
                            title: this.$t('Modal.error'),
                            content: this.$t('/user/register/web/sso.400'),
                            show_cancel: false,
                            success: () => {
                                window.location.href = 'https://tripleuni.com/landing/'
                            }
                        })
                        return
                    }
                    if (res.code == 401) {
                        this.$modal.show({
                            title: this.$t('Modal.error'),
                            content: this.$t('/user/register/web/sso.401'),
                            show_cancel: false,
                            success: () => {
                                window.location.href = 'https://tripleuni.com/landing/'
                            }
                        })
                    }
                    if (res.code == 500) {
                        window.location.href = 'https://login.tripleuni.com/TripleUni?callback=' + encodeURIComponent(this.$route.query.callback)
                        return
                    }

                    localStorage.clear()
                    localStorage.setItem('token', res.token)
                    localStorage.setItem('user_itsc', res.user_itsc)
                    localStorage.setItem('user_school_label', res.user_school_label)
                    if (res.user_is_org) {
                        localStorage.setItem('user_is_org', true)
                    }
                    
                    if (this.$route.query.callback) {
                        this.$router.replace({
                            path: this.$route.query.callback,
                            replace: true
                        })
                    } else {
                        this.$router.replace({
                            path: '/home',
                            replace: true
                        })
                    }

                    customize()
                    launchWebSocket()
                    checkUnreadNotice()
                    this.checkWebpush()
                })
        },
        checkWebpush() {
            if (!("Notification" in window)) {
                return
            } else if (Notification.permission === "granted") {
                subscribeWebpush()
            } else if (Notification.permission == "denied") {
                this.$modal.show({
                    title: this.$t('Webpush.deny_title'),
                    content: this.$t('Webpush.deny_content'),
                    show_cancel: false,
                })
            } else {
                this.$modal.show({
                    title: this.$t('Webpush.login_title'),
                    content: this.$t('Webpush.login_content'),
                    show_cancel: true,
                    success: (res) => {
                        if (!res) return
                        Notification.requestPermission().then((permission) => {
                            if (permission === "granted") {
                                subscribeWebpush(true)
                            } else {
                                this.$modal.show({
                                    title: this.$t('Webpush.deny_title'),
                                    content: this.$t('Webpush.deny_content'),
                                    show_cancel: false,
                                })
                            }
                        })
                    }
                })
            }
        }
    }
}
</script>


<style scoped>
.body {
    background-color: var(--bg);
    color: var(--text);
    margin: 0;
    font-family: "Chirp", sans-serif;
    overflow-x: hidden;
    display: flex;
    height: 100vh;
    height: calc(100vh - env(safe-area-inset-bottom));
    align-items: center;
}
</style>