<template>
  <div style="width: 100%;">
    <Navbar :title="$t('MiddleNotice.title')" :show_back="show_back" />
    <Refresh :on-refresh="onRefresh">
      <div class="notice-outer" v-for="(notice, index) in notice_list" :key="index" @click="readNotice(notice)">
        <div class="notice-tip" v-show="!notice.notice_is_read" />
        <div class="notice-msg" v-if="notice.notice_type == 'post_new_comment'">{{ $t('MiddleNotice.post_new_comment', {post_id: notice.notice_data.post_id}) }}</div>
        <div class="notice-msg" v-else-if="notice.notice_type == 'comment_new_reply'">{{ $t('MiddleNotice.comment_new_reply', {post_id: notice.notice_data.post_id}) }}</div>
        <div class="notice-msg" v-else-if="notice.notice_type == 'follow_post_update'">{{ $t('MiddleNotice.follow_post_update', {post_id: notice.notice_data.post_id}) }}</div>
        <div class="notice-msg" v-else>{{ $t('MiddleNotice.default') }}</div>
        <div class="notice-time">{{ notice.notice_create_time_display }}</div>
      </div>
      <LoadMore :is_last="is_last" v-show="is_loading_more || is_last" />
    </Refresh>
  </div>
</template>
    
<script>
import request from '../utils/request.js'
import formatTime from '../utils/formatTime.js'
import { checkUnreadNotice } from '../utils/unread.js'
import Navbar from './Navbar.vue'
import LoadMore from './LoadMore.vue'

export default {
  name: 'UniMiddleNotice',
  components: { Navbar, LoadMore },
  created() {
    this.$loading.show()
    this.getNotice()
  },
  activated() {
    window.addEventListener('scroll', this.handleScroll, true);
    window.addEventListener('resize', this.handleScreenWidthChange);
    this.handleScreenWidthChange();
    if (this.$route.query.method == 'refresh') {
      this.$loading.show()
      window.scrollTo(0, 0)
      this.page = 0
      this.is_loading_more = true
      this.getNotice()
      this.$router.replace('/notice')
    }
    const user_school_label = localStorage.getItem('user_school_label')
    if (user_school_label == "HKU") {
      document.title = this.$t('MiddleNotice.title') + " / " + this.$t('AppNameTitle.HKU')
    }
    if (user_school_label == "CUHK") {
      document.title = this.$t('MiddleNotice.title') + " / " + this.$t('AppNameTitle.CUHK')
    }
    if (user_school_label == "UST") {
      document.title = this.$t('MiddleNotice.title') + " / " + this.$t('AppNameTitle.UST')
    }
  },
  deactivated() {
    window.removeEventListener('scroll', this.handleScroll, true);
    window.removeEventListener('resize', this.handleScreenWidthChange);
  },
  data() {
    return {
      page: 0,
      notice_list: [],
      is_last: false,
      is_loading_more: false,
      finishRefresh: null,
      show_back: false
    }
  },
  methods: {
    getNotice() {
      request("/notice/get", {
        page: this.page
      })
        .then((res) => {
          this.$loading.hide()
          if (this.finishRefresh) this.finishRefresh()
          if (!res) return
          this.is_loading_more = false
          this.is_last = res.is_last
          res.notice_list.forEach(notice => {
            notice.notice_create_time_display = formatTime(notice.notice_create_time)
          });
          if (this.page == 0) {
            this.notice_list = res.notice_list
          } else {
            this.notice_list = this.notice_list.concat(res.notice_list)
          }
        })
    },
    readNotice(notice) {
      this.notice_list = this.notice_list.map(item => {
        if (item.notice_url == notice.notice_url && item.notice_type == notice.notice_type) {
          item.notice_is_read = true
        }
        return item
      })
      if (notice.notice_data.uni_post_id) {
        this.$router.push("/post/" + notice.notice_data.uni_post_id)
      }
      request("/notice/read", {
        notice_id: notice.notice_id
      })
        .then((res) => {
          if (!res) return
          checkUnreadNotice()
        })
    },
    onRefresh(finishRefresh) {
      this.finishRefresh = finishRefresh
      this.page = 0
      this.is_loading_more = true
      this.getNotice()
    },
    loadMore() {
      if (this.is_loading_more) return;
      if (this.is_last) return;
      this.is_loading_more = true
      this.page = this.page + 1
      this.getNotice()
    },
    handleScroll() {
      const scrollHeight = document.documentElement.scrollHeight
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const clientHeight = document.documentElement.clientHeight
      if (scrollTop + clientHeight >= scrollHeight - 1400) {
        this.loadMore()
      }
    },
    handleScreenWidthChange() {
      if (window.innerWidth <= 700) {
        this.show_back = true
      } else {
        this.show_back = false
      }
    }

  }
}
</script>

<style scoped>
.notice-outer {
  border-bottom: 0.1rem solid var(--border-grey);
  padding: 15px 10px 45px 15px;
  position: relative;
  cursor: pointer;
  background-color: var(--bg);
}

.notice-outer:hover {
  background-color: var(--hover-grey);
}

.notice-msg {
  font-size: 15px;
  white-space: pre-wrap;
}

.notice-time {
  margin-top: 20px;
  font-size: 11px;
  color: var(--text-grey);
  float: right
}

.notice-tip {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--tip);
}

@media (hover: none) {
  .notice-outer:hover {
    background-color: inherit;
  }

}
</style>